.footer {
    position: fixed;
    bottom: 0;
    z-index: 10;
}

.byteLogo {
    position: fixed;
    bottom: 5vh;
    left: 5vw;
    z-index: 10;
    width: 20vw;
}

.footerBackground{
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    padding-left: 2.4vw;
}

.footerBigText {
    position: fixed;
    bottom: 0;
    left: 30vw;

    font-family: "BlackSteel";
    font-size: 4vw;
    text-align: center;
    
    margin-right: 4vw;

    z-index: 15;
}

.meetByteButton {
    position: fixed;
    right: 2vw;
    bottom: 23.3vw;

    font-family: "BlackSteel";

    border: 0.5vw solid #2a9db0;
    border-radius: 2vw;
    background-color: none;

    min-width: 33vw;
    max-width: 33vw;
    min-height: 10vw;
    max-height: 10vw;
    
    transition: background-color 1s linear;

    z-index: 25;
}

.meetByteButton:hover {
    transition: background-color 1s linear;
    background-color: white;
}

.footerMeetByteText {
    text-align: left;

    font-size: 6vw;
    text-align: center;

    margin-left: 0.5vw;
    margin-bottom: 0vw;
    margin-top: 0vw;
}

.footerMeetByteTextSmall {
    text-align: left;

    font-size: 3vw;
    text-align: center;

    margin-left: 0.5vw;
    margin-bottom: 0vw;
    margin-top: 1vw;
}

.footerMediumText {
    position: fixed;
    bottom: 5.4vw;
    right: 0;
    width: 68vw;

    font-family: "Kimberley";
    font-size: 3.5vw;
    text-align: right;
    
    margin-right: 2vw;
    margin-top: 0vw;
    margin-bottom: 1vw;

    z-index: 15;
}

.seeWhat {
    font-family: "BlackSteel";
    font-size: 3vw;
    text-align: left;
    
    margin-left: 1vw;
    margin-top: 0.3vw;

    z-index: 15;
}

.footerButtonByte {
    position: fixed;
    display: flex;
    right: 2vw;
    bottom: 2vw;

    font-family: "BlackSteel";
    text-decoration: none;

    border: 0.5vw solid #2a9db0;
    border-radius: 2vw;
    background-color: none;

    min-width: 68vw;
    max-width: 68vw;
    min-height: 3vw;
    max-height: 3vw;

    transition: background-color 1s linear;

    z-index: 25;
}

.footerButtonByte:hover {
    background-color: white;

    transition: background-color 1s linear;
}

.arrow {
    width: 15vw;
    
    margin-left: 3vw;
    margin-top: 0.3vw;

    z-index: 15;
}