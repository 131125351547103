.App, .AppMobile {
  position: relative;
  text-align: center;
}

.Img, .MobileImg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  min-width: 105vw;
  max-width: 105vw;
  min-height: 105vh;
  max-height: 105vh;
}