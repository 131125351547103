.bodyText{

    text-align: left;
    color: white;
    font-family: "Stigma";
    font-size: 6vw;

    margin-left: 10vw;
    margin-right: 10vw;
}

.moreDetails {
    font-size: 10vw;
    text-align: center;
}

.whiteLine {
    height: 0.5vw;
    width: 80vw;


    background-color: white;

    margin-left: 10vw;
}